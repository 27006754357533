import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment-form"
export default class extends Controller {
  static targets = ['form', 'body', 'fileField', 'editor', 'dynamicDropdown'];

  // values
  static values = {
    mentionablePeople: Array
  }

  connect() {
    // listen for file field changes to not exceed data-max-file-size in bytes to set on that field
    this.fileFieldTarget.addEventListener('change', this.validateFileSize.bind(this));

    this.quill = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        toolbar: false
      }
    });

    // focus the editor if clicked on editorTarget
    this.editorTarget.addEventListener('click', () => {
      this.quill.focus();
    });

    this.quill.on('text-change', (delta, oldDelta, source) => {
      if (source == 'user') {
        let cursorPosition = this.quill.getSelection().index;

        // handle case when there's only one character in the editor and cursor position is 0.
        // In cases like this one it should be set to 1.
        // I don't know, it could be a bug
        if (cursorPosition === 0 && this.quill.getText().length === 2) {
          cursorPosition = 1;
        }

        const textBeforeCursor = this.quill.getText(0, cursorPosition);

        // Check if the last character is "@" or string starts with "@"
        if (textBeforeCursor.slice(-1) === "@" || /@\S*$/.test(textBeforeCursor)) {

          // Here, you would invoke your function
          let match = textBeforeCursor.match(/@\S*$/);
          const mentionText = match !== null ? match[0] : "";
          this.handleMentioning(mentionText, cursorPosition);
        } else {
          this.hideMentionsDropdown();
        }

        // Now, validate and format all mentions in the document
        this.validateAndFormatAllMentions();
      }

      this.copyRawTextFromEditorToBody();
    });
  }

  copyRawTextFromEditorToBody() {
    let rawText = this.quill.getText();

    // all the valid mentions should be replaced with ${mention:user_id}
    this.mentionablePeopleValue.forEach((person) => {
      rawText = rawText.replace(new RegExp(`@${person.mention_string}`, 'g'), `\${betahub_mention:${person.id}}`);
    });

    // body is a textarea element
    this.bodyTarget.value = rawText;
  }

  mentionItemSelected({ detail: { id } }) {
    let mentionItem = this.mentionablePeopleValue.find(person => person.id == id);

    // Get the current cursor position
    let cursorPosition = this.quill.getSelection().index;

    // Calculate the start position of the mention
    let mentionStart = cursorPosition - this.currentMentionText.length;

    // Delete the entered part of the mention
    this.quill.deleteText(mentionStart, this.currentMentionText.length);

    // Insert the new text
    this.quill.insertText(mentionStart, `@${mentionItem.name} `);
    this.validateAndFormatAllMentions();

    this.hideMentionsDropdown();
  }

  handleMentioning(mentionText, cursorPosition) {
    this.currentMentionText = mentionText;

    // Here, calculate the cursor's screen position. This is a bit tricky
    // and might involve some DOM manipulation or using the Quill Bounds.
    const bounds = this.quill.getBounds(cursorPosition);

    // Assuming you have a way to translate Quill's bounds to screen coordinates
    const screenCoordinates = this.getScreenCoordinatesFromQuillBounds(bounds);

    // Execute your function with mention text and screen coordinates
    this.showMentionsDropdown(screenCoordinates, mentionText, { at: cursorPosition });
  }

  getScreenCoordinatesFromQuillBounds(bounds) {
    // This is an example. You'll need to adjust this based on your actual UI layout.
    const containerRect = this.editorTarget.getBoundingClientRect();

    return {
      x: containerRect.left + bounds.left,
      y: containerRect.top + bounds.top
    };
  }

  showMentionsDropdown(screenCoordinates, mentionText, { at }) {
    let itemsArray = this.mentionItems(mentionText);

    if (itemsArray.length === 0) {
      this.hideMentionsDropdown();
      return;
    }

    this.dynamicDropdown().setItems({ items: itemsArray });
    this.dynamicDropdown().show(this.editorTarget.childNodes[0]);
    this.dynamicDropdown().setPosition({ x: screenCoordinates.x, y: screenCoordinates.y + 20 });
  }

  mentionItems(mentionText) {
    let itemsArray = []
    if (!mentionText) {
      return itemsArray;
    }

    if (mentionText.startsWith('@')) {
      mentionText = mentionText.slice(1);
    }

    this.mentionablePeopleValue.forEach((person) => {
      if (mentionText === "" || person.name.toLowerCase().startsWith(mentionText.toLowerCase())) {
        itemsArray.push({id: person.id, html: person.name})
      }
    });

    return itemsArray;
  }

  hideMentionsDropdown() {
    this.dynamicDropdown().hide();
  }


  removeAllMentionFormatting() {
    let textLength = this.quill.getLength();
    const text = this.quill.getText();

    // If the text ends with a newline, adjust the length to exclude it
    if (text.slice(-1) === '\n') {
      textLength--;
    }

    this.quill.removeFormat(0, textLength);
  }

  validateAndFormatAllMentions() {
    this.removeAllMentionFormatting();

    const text = this.quill.getText();
    const formatRegex = /@\S+/g;
    let match;

    while ((match = formatRegex.exec(text)) !== null) {
      const mention = match[0];
      const isValid = this.checkMentionValidity(mention);

      if (isValid) {
        this.applyMentionFormatting(match.index, mention.length, true);
      }
    }
  }

  applyMentionFormatting(index, length, isValid) {
    const formatName = 'color'; // Or use a custom style/class name
    const formatValue = isValid ? 'blue' : 'black'; // Choose colors or add/remove your formatting based on validity
    this.quill.formatText(index, length, { [formatName]: formatValue }, 'silent');
  };

  checkMentionValidity(mention) {
    return this.mentionablePeopleValue.some(person => person.mention_string === mention.slice(1));
  };

  clearForm(event) {
    if (event.detail.success) {
      this.bodyTarget.value = '';
      this.fileFieldTarget.value = '';
    }
  }

  validateFileSize(event) {
    const maxFileSize = this.fileFieldTarget.dataset.maxFileSize;
    const file = this.fileFieldTarget.files[0];
    if (file.size > maxFileSize) {
      alert('File size exceeds the maximum allowed size');
      this.fileFieldTarget.value = '';
    }
  }

  dynamicDropdown() {
    return this.application.getControllerForElementAndIdentifier(this.dynamicDropdownTarget, 'dynamic-dropdown');
  }
}