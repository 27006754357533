import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static values = {
    endpoint: String,
    skipIds: Array
  }

  static targets = [
    "searchResults",
    "issueIdField"
  ]

  connect() {
    // if search results are visible, hide them on any click outside the search box
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.search-results') && !event.target.closest('.search')) {
        this.hideSearchResults();
      }
    });
  }

  areSearchResultsVisible() {
    document.querySelectorAll('.search-results').forEach((element) => {
      return element.style.display !== 'none';
    });

    return false
  }

  hideSearchResults() {
    document.querySelectorAll('.search-results').forEach((element) => {
      element.style.display = 'none';
    });
  }

  updateFrame(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const query = event.target.value.trim();
      let url = `${this.endpointValue}?skip_ids=${this.skipIdsValue.join(',')}`

      if (query.startsWith('#') && !isNaN(query.substring(1))) {
        this.searchResultsTarget.src = `${url}&scoped_id=${encodeURIComponent(query.substring(1))}`;
      } else if (!isNaN(query) && query.length > 0) {
        this.searchResultsTarget.src = `${url}&scoped_id=${encodeURIComponent(query)}`;
      } else if (query.length >= 3) {
        this.searchResultsTarget.src = `${url}&query=${encodeURIComponent(query)}`;
      } else {
        this.hideSearchResults()
      }
    }, 200); // Delay to prevent too frequent requests
  }

  selectIssue(event) {
    const issueElement = event.target.closest('[data-issue-id]');

    if (issueElement) {
      // Read data-issue-id from the parent element
      const issueId = issueElement.dataset.issueId;
      this.issueIdFieldTarget.value = issueId;
    } else {
      console.error('Issue element not found');
    }

    // this controller is connected to the form
    this.element.submit();
  }
}


window.selectIssue = function(issueId) {
  document.getElementById('issue_id').value = issueId;
  document.getElementById('issue_search_form').submit();
}
