// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./custom/videojs_reload"
import "./custom/timezone"
import "./custom/recent_issues"
import ClipboardJS from 'clipboard';

function initializeClipboard() {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    const clipboard = new ClipboardJS('.btn[data-clipboard-target]');

    // Optional: Add event listeners for clipboard.js events
    clipboard.on('success', function (e) {
        console.log('Text copied:', e.text);

        e.trigger.setAttribute('data-bs-original-title', 'Copied!');
        const tooltipInstance = bootstrap.Tooltip.getInstance(e.trigger);
        tooltipInstance.show();

        // Reset the tooltip text after a short delay
        setTimeout(() => {
            e.trigger.setAttribute('data-bs-original-title', 'Copy to clipboard');
            tooltipInstance.hide();
        }, 1000);

        e.clearSelection();
    });

    clipboard.on('error', function (e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });
}

function initializeChoices(el) {
    const smartValueComparer = (a, b) => {
        const keywords = a.trim().toLowerCase().split(' ');
        const target = b.trim().toLowerCase();
        let rank = 0;

        // Check for exact match
        if (a.toLowerCase() === b.toLowerCase()) {
            return 0;
        }

        // Check for matching keywords and order
        keywords.forEach((keyword, index) => {
            if (target.includes(keyword)) {
                rank += 10;

                // Check if the keyword is in the correct order
                const position = target.indexOf(keyword);
                if (index > 0 && target.slice(0, position).includes(keywords[index - 1])) {
                    rank += 5;
                }
            }
        });

        // Bonus for a higher count of matching keywords
        const keywordMatches = target.split(' ').filter(word => keywords.includes(word));
        rank -= keywordMatches.length;

        return rank;
    };

    // query all '.js-choice' elements within the given element
    el.querySelectorAll('.js-choice').forEach((element) => {
        new Choices(element, {
            placeholder: true,
            searchResultLimit: 10,
            fuseOptions: {
                findAllMatches: 2,
                distance: 50
            }
        });
    });
}

document.addEventListener('turbo:render', () => {
    initializeClipboard();
    initializeChoices(document);
    // check if Dropzone is defined
    if (typeof Dropzone !== 'undefined') {
        Dropzone.autoDiscover = false;
    }
});

document.addEventListener('turbo:load', () => {
    initializeClipboard();
    initializeChoices(document);
    if (typeof Dropzone !== 'undefined') {
        Dropzone.autoDiscover = false;
    }
});